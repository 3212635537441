import React from "react"
import styled from "styled-components"
import ReCAPTCHA from "react-google-recaptcha"
import Layout from "../components/layout"

const FormBox = styled.div `
    margin: 10vh;
    box-shadow: -5px 5px 20px rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    background-color: white;

    @media (max-width: 768px){
      margin: 2%;
    }
`

const Form = styled.form `
    display: flex;
    margin: 7vh 14vw;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: ${props => (props.reveal  ? "50vh" : "auto")};
  
    * {
      margin: 1vh;
    }
`

const SendButton = styled.button `
    align-self: center;
    padding: 1em;
    border-style: none;
    border-radius: 8px;
    background: linear-gradient(95.51deg, #036B63 -16.61%, #9CDFC3 171.13%);
    cursor: pointer;
    color: white;
    margin-bottom: 4vh;
    
    :active {
    border-style: solid;
    border-width: thin;
    color: #000;
    }
`
const Message = styled.h2`
  opacity: ${props => (props.reveal  ? "1" : "0")};
  font-size: 1em;
  margin: auto;
  text-align: center;  
`

const Title = styled.h1 `
  padding: 4vh;
  text-align: center;
`

const GoogleCaptcha = styled(ReCAPTCHA) `
    text-align: center;

@media (max-width: 768px){
  transform:scale(0.7);
  transform-origin: -12 0;
  }
`

 const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }


  class ContactForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = { name: "", email: "", message: "", errorName: "", errorEmail: "" };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.recaptchaRef = React.createRef();
    }

    componentDidMount() {
      document.body.style.background = 'linear-gradient(95.51deg, #036B63 -16.61%, #9CDFC3 171.13%)';
    }

    componentWillUnmount() {
      document.body.style.background = 'white';
    }

    handleValidation(){
      let formIsValid = true;
      let name = this.state.name;
      let email = this.state.email;
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');

      if(!name){
        formIsValid = false;
        this.setState({errorName: "This cannot be empty"})
      }

      if(!email){
        formIsValid = false;
        this.setState({errorEmail: "This cannot be empty"})
      }
        else if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
          formIsValid = false;
          this.setState({errorEmail: "Email is not valid"})
      }  

      return formIsValid;
    }
    

    handleSubmit = e => {
      const recaptchaValue = this.recaptchaRef.current.getValue();

      e.preventDefault();
      
      if(this.handleValidation() && recaptchaValue){
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact", ...this.state })  
        })
          .then(() => this.setState({thanks: this.thanks = !this.thanks}))
          .catch(error => alert(error));
      }
    };

    handleChange = e => this.setState({ [e.target.name]: e.target.value });

    
    render() {
      const { name, email, message, thanks } = this.state;
      return (
        
        <Layout>
          <FormBox>
              <Title>Contact Us</Title>
                {thanks ? (
                    <Form reveal>
                      <Message reveal>Someone will get back to you about this as soon as we can.</Message>
                     </Form>

                ) : (
             
                    <Form onSubmit={this.handleSubmit} netlify-honeypot="bot-field" data-netlify="true" name="contact">
                      
                      <input type="hidden" name="form-name" value="contact" />
                      <h3 hidden>
                      <label>
                          Don’t fill this out <input name="bot-field" onChange={this.handleChange} />
                      </label>
                      </h3>
                        <input type="text" name="name" value={name} placeholder="Name" onChange={this.handleChange} />
                        <span style={{color: "red"}}>{this.state.errorName}</span>
                        <br />
                        <input type="email" name="email" value={email} placeholder="Email" onChange={this.handleChange} />
                        <span style={{color: "red"}}>{this.state.errorEmail}</span>
                        <br />
                        <textarea name="message" value={message} placeholder="Message" rows="5" onChange={this.handleChange} />
                        <br />
                        <GoogleCaptcha sitekey={process.env.SITE_RECAPTCHA_KEY} ref={this.recaptchaRef} onChange={this.handleChange} />
                        <br />
                        <SendButton type="submit" aria-label="Send Button">Send Your Message</SendButton>
                        <Message />
                      </Form>
                )}
          </FormBox>
        </Layout>
       
      );
    }
  }


export default ContactForm


  